<template>
  <div
    v-if="
      $store.state.fastPermissoes.ativo == 'S' &&
        ($store.state.fastPermissoes.administrador == 'S')
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Meus cursos</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Gerenciamento de
                  <span id="txtDashboardNomePlataforma">cursos</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->

              <section
                v-if="
                  $store.state.fastPermissoes.administrador == 'S'
                "
                class="fd-app-content"
              >
                <div class="text-center mt-3">
                  <a
                    class="btn btn-primary btn-xs mt-2"
                    href="#"
                    @click.prevent="getFastCursosDisponiveis"
                  >Cursos prontos disponíveis</a>
                  <a
                    class="btn btn-primary btn-lg-max ml-2 mt-2"
                    href="#"
                    @click.prevent="showModal('modalCriarCurso')"
                  >+ Criar Curso</a>
                  <modal
                    name="modalCriarCurso"
                    :scrollable="true"
                    height="auto"
                    :shift-y="0.1"
                    :focus-trap="true"
                    :adaptive="true"
                  >
                    <div class="row p-4">
                      <div class="col-6 pl-4 pr-4 text-left">
                        <h4>Criar curso</h4>
                      </div>
                      <div class="col-6 pl-4 pr-4 text-right">
                        <a
                          class="btn btn-secondary"
                          href="#"
                          @click.prevent="hideModal('modalCriarAula')"
                        >
                          <i
                            class="fa fa-times"
                            aria-hidden="true"
                          />
                        </a>
                      </div>
                      <div class="col-12 p-4">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <input
                                id="txtTituloFastCurso"
                                class="form-control"
                                type="text"
                                placeholder="Titulo do Curso"
                              >
                              <textarea
                                id="txtDetalheFastCurso"
                                class="form-control mt-2"
                                placeholder="Descrição do Curso"
                              />
                            </div>
                            <div class="form-group mb-0 text-left">
                              <button
                                href="#"
                                type="button"
                                class="btn btn-primary"
                                @click.prevent="criaFastCurso"
                              >
                                Criar Curso
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </modal>
                  <!-- #welcome-modal-->
                  <div
                    id="welcome-modal"
                    class="modal fade"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-lg"
                      role="document"
                    >
                      <div class="modal-content mt-5">
                        <div
                          class="modal-header"
                          style="
                            background-image: url('/public/images/meuscursos-modal-bg.jpg');
                            background-size: cover;
                            background-position: center top;
                            min-height: 300px;
                          "
                        >
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <h3>Bem-vindo à sua plataforma!</h3>
                          <p>
                            Essa é a interface administrativa de seus cursos.
                          </p>
                          <button
                            class="btn btn-primary"
                            type="button"
                            data-dismiss="modal"
                          >
                            Ok, entendi
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- #welcome-modal -->
                </div>
                <div class="fd-plataforma-gestao" />
              </section>

              <section class="fd-app-meus-cursos">
                <div
                  class="fd-app-meus-cursos-filtros mb-4"
                  style
                >
                  <h3 class="aluno_font_color">
                    Filtrar Cursos
                  </h3>
                  <div class="fd-app-meus-cursos-filtros-form">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-5">
                        <div
                          class="easy-autocomplete eac-bootstrap position-relative"
                          style="width: 0px"
                        >
                          <Autocomplete
                            :get-label="getLabel"
                            :input-attrs="{
                              class: 'form-control',
                              placeholder: 'Nome do curso',
                            }"
                            :items="fastMeusCursosAutoComplete"
                            :component-item="CursosAutoComplete"
                            @update-items="updateItems"
                            @item-clicked="clickCurso"
                          />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-5 d-none">
                        <select class="form-control">
                          <option>Disciplina</option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-4 mt-4 d-none">
                        <select
                          id="selFiltroCursoSegmentos"
                          class="form-control"
                        >
                          <option value="0">
                            Segmento
                          </option>
                          <option value="1">
                            PREFEITURA E ÓRGÃOS PÚBLICOS
                          </option>
                          <option value="2">
                            INSTITUIÇÕES DE ENSINO
                          </option>
                          <option value="3">
                            INDÚSTRIA
                          </option>
                          <option value="4">
                            COMÉRCIO
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-3 d-none">
                        <select
                          id="selFiltroCursoAcesso"
                          class="form-control"
                        >
                          <option value="0">
                            Públicos e Restritos
                          </option>
                          <option value="P">
                            Somente públicos
                          </option>
                          <option value="R">
                            Somente restrito
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-4">
                        <select
                          id="selFiltroCursoPublicado"
                          v-model="filtroCursosPublicados"
                          class="form-control"
                          @change="filtraCursoPublicado()"
                        >
                          <option value="3">
                            Publicados e não publicados
                          </option>
                          <option value="1">
                            Somente publicados
                          </option>
                          <option value="0">
                            Somente não publicados
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-4 mt-4 d-none">
                        <select
                          id="selFiltroCursoLiberacao"
                          class="form-control"
                        >
                          <option value="0">
                            Liberados e não liberados
                          </option>
                          <option value="S">
                            Somente liberados automaticamente
                          </option>
                          <option value="N">
                            Somente não liberados
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-4 mt-4 d-none">
                        <select
                          id="selFiltroCursoPreco"
                          class="form-control"
                        >
                          <option value="0">
                            Com preço e sem preço
                          </option>
                          <option value="S">
                            Somente com preço
                          </option>
                          <option value="N">
                            Somente sem preço
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                        <a
                          v-if="
                            $store.state.fastPermissoes.administrador == 'S'
                          "
                          class="btn btn-primary btn-xs"
                          href="#"
                          @click.prevent="getFastCursos"
                        >Buscar cursos da plataforma</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fd-app-meus-cursos-list">
                  <div
                    id="divExibeFastCursos"
                    class="row"
                  >
                    <div class="col-12 text-center">
                      <h3
                        v-if="fastCursosDisponiveis"
                        class="aluno_font_color"
                      >
                        Cursos disponíveis
                        <span
                          class="total-meus-cursos"
                        >({{ fastMeusCursosAutoComplete.length }})</span>
                      </h3>
                      <h3
                        v-else
                        class="aluno_font_color"
                      >
                        Cursos da plataforma
                        <span
                          class="total-meus-cursos"
                        >({{ fastMeusCursosAutoComplete.length }})</span>
                      </h3>
                    </div>
                    <div
                      v-if="fastMeusCursosLoading"
                      class="col-12 mt-4 text-center"
                    >
                      <h4 v-html="fastLoadingDados()" />
                    </div>
                    <div
                      v-for="curso in pageOfItems"
                      v-else
                      :key="curso.id_curso"
                      class="col-sm-12 col-md-12 col-lg-4"
                    >
                      <div class="fd-app-meus-cursos-list-item">
                        <div
                          class="fd-app-meus-cursos-list-item-header"
                          style="min-height: 130px; position: relative"
                        >
                          <div
                            v-if="curso.publicado"
                            style="position: absolute; right: 0; top: 0"
                          >
                            <b-icon-check-circle-fill
                              font-scale="2"
                              variant="warning"
                            /> 
                          </div>
                          <h3 v-if="curso.nome_curso">{{ curso.nome_curso }}</h3>
                          <h3 v-else>{{ curso.nome_curso_mae }}</h3>

                          <p v-if="curso.detalhe_curso">{{ curso.detalhe_curso }}</p>
                          <p v-else>{{ curso.detalhe_curso_mae }}</p>
                        </div>
                        <div class="fd-app-meus-cursos-list-item-body">
                          <img
                            v-if="curso.logo_curso"
                            :src="
                              curso.logo_curso
                                ? ajustaLinkApiAntiga(curso.logo_curso)
                                : imageSitePlataformaImg
                            "
                            style="max-height: 170px"
                            class="img-fluid"
                          >
                          <img
                            v-else
                            :src="
                              curso.logo_curso_mae
                                ? ajustaLinkApiAntiga(curso.logo_curso_mae)
                                : imageSitePlataformaImg
                            "
                            style="max-height: 170px"
                            class="img-fluid"
                          >
                          <div class="mt-4">
                            <div
                              class="row fd-app-meus-cursos-list-item-action"
                            >
                              <div
                                v-if="!fastCursosDisponiveis"
                                class="col-sm-12 col-md-12 col-lg-5 text-center mb-2"
                              >
                                <a
                                  class="btn btn-primary btn-md"
                                  :href="
                                    '/plataforma/' +
                                      $route.params.id_plataforma +
                                      '/admin/curso/' +
                                      curso.id_curso +
                                      '/aulas'
                                  "
                                  @click.prevent="$router.push('/plataforma/' +
                                    $route.params.id_plataforma +
                                    '/admin/curso/' +
                                    curso.id_curso +
                                    '/aulas')"
                                >Aulas</a>
                              </div>
                              <div
                                v-if="
                                  $store.state.fastPermissoes.administrador ==
                                    'S'
                                "
                              >
                                <div
                                  v-if="fastCursosDisponiveis"
                                  class="col-sm-12 col-md-12 col-lg-12 text-center"
                                >
                                  <button
                                    class="btn btn-success btn-md"
                                    @click="insereCursoMae(curso)"
                                  >
                                    + Adicionar curso
                                  </button>
                                </div>
                                <div
                                  v-else
                                  class="col-sm-12 col-md-12 col-lg-7 text-center"
                                >
                                  <a
                                    class="btn btn-primary btn-md"
                                    :href="
                                      '/plataforma/' +
                                        $route.params.id_plataforma +
                                        '/admin/curso/' +
                                        curso.id_curso
                                    "
                                    @click.prevent="$router.push('/plataforma/' +
                                      $route.params.id_plataforma +
                                      '/admin/curso/' +
                                      curso.id_curso)"
                                  >Gerenciar Curso</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 text-center mt-4">
                      <Pagination
                        :page-size="9"
                        :items="fastMeusCursosAutoComplete"
                        @changePage="pageOfItems = $event"
                      />
                    </div>
                  </div>
                </div>
              </section>

              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' + $route.params.id_plataforma + '/admin'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
//import ImageSiteFormEffect01 from "@/assets/images/form-effect-01.png";
import imageSitePlataformaImg from "@/assets/images/app/plataforma-img.jpg";
import Pagination from "../components/Pagination";
import Autocomplete from "v-autocomplete";
import CursosAutoComplete from "../components/CursosAutoComplete";

export default {
  name: "HomeInternoAdminMeusCursos",
  components: { Pagination, Autocomplete },
  mixins: [methods],
  data: function () {
    return {
      //ImageSiteFormEffect01,
      imageSitePlataformaImg,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      fastMeusCursos: [],
      fastMeusCursosLoading: true,
      fastMeusCursosAutoComplete: [],
      pageOfItems: [],
      CursosAutoComplete,
      Autocomplete,
      fastCursosDisponiveis: false,
      filtroCursosPublicados: 3
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.getFastCursos();
/*
          if (
            this.$store.state.fastPermissoes.ativo == "S" &&
            this.$store.state.fastPermissoes.administrador == "S"
          ) {
            this.getFastCursos();
          } else if (this.$store.state.fastPermissoes.professor_only) {
            this.getFastCursosProfessor();
            this.exibeToasty(
              "Você está acessando com perfil de professor",
              "info"
            );
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Você não possui permissões", "error");
          }
          */
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    // Autocomplete
    getLabel(item) {
      if (item.nome_curso) return item.nome_curso
      else
        return item.nome_curso_mae
    },
    // Autocomplete
    updateItems(text) {
      this.fastMeusCursosAutoComplete = this.fastMeusCursos.filter(
        (e) => {
          if (e.nome_curso) return e.nome_curso.toLowerCase().indexOf(text.toLowerCase()) != "-1"
          else
            return e.nome_curso_mae.toLowerCase().indexOf(text.toLowerCase()) != "-1"
        }
      );
    },
    // Autocomplete
    clickCurso(text) {
      this.fastMeusCursosAutoComplete = [];
      this.fastMeusCursosAutoComplete.push(text);
    },
    async getFastCursos() {
      this.fastMeusCursosLoading = true
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_plataforma?id_usuario=0" +
            "&id_plataforma=" +
            this.retornaSessao(settings.fastSessaoPlataforma).id_plataforma +
            "&id_curso=",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let objPlataforma = Array.from(json);
        if (objPlataforma.length > 0) {
          this.fastCursosDisponiveis = false;
          this.fastMeusCursos = objPlataforma;
          this.fastMeusCursosAutoComplete = objPlataforma;
        }

        this.$store.state.fastCarregando = false;
        this.fastMeusCursosLoading = false
      } catch (e) {
        this.$store.state.fastCarregando = false;
        this.exibeToasty(e, "error");
        this.fastMeusCursosLoading = false
      }
    },
    async getFastCursosProfessor() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_plataforma/lista_cursos_professor?&id_plataforma=" +
            this.retornaSessao(settings.fastSessaoPlataforma).id_plataforma +
            "&id_curso=",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let objPlataforma = Array.from(json);
        if (objPlataforma.length > 0) {
          //          console.log("getFastCursos", objPlataforma);
          this.fastCursosDisponiveis = false;
          this.fastMeusCursos = objPlataforma;
          this.fastMeusCursosAutoComplete = objPlataforma;
        }
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
      }
    },
    async getFastCursosDisponiveis() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso/lista_cursos_maes?id_plataforma=" +
            this.retornaSessao(settings.fastSessaoPlataforma).id_plataforma +
            "&id_curso=",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let objPlataforma = Array.from(json);
        console.log("getFastCursosDisponiveis", objPlataforma);
        if (objPlataforma.length > 0) {          
          this.fastCursosDisponiveis = true;
          this.fastMeusCursos = objPlataforma;
          this.fastMeusCursosAutoComplete = objPlataforma;
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    criaFastCurso(e) {
      // Validação
      if (document.querySelector("#txtTituloFastCurso").value == "") {
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Erro",
          text: "O nome do curso não pode ficar em branco.",
        });
      } else {
        this.$store.state.fastCarregando = true;
        // Monta objeto para ser enviado via POST
        let fast_curso = new Object();
        fast_curso.nome_curso = document.querySelector(
          "#txtTituloFastCurso"
        ).value;
        if (!document.querySelector("#txtDetalheFastCurso").value)
          fast_curso.detalhe_curso = "Sem detalhes";
        else
          fast_curso.detalhe_curso = document.querySelector(
            "#txtDetalheFastCurso"
          ).value;
        fast_curso.publicado = false;
        fast_curso.carga_horaria = "30";
        fast_curso.origem = "OC";
        fast_curso.id_plataforma_origem = this.retornaSessao(
          settings.fastSessaoPlataforma
        ).id_plataforma;
        let fast_curso_plataforma = new Object();
        fast_curso_plataforma.id_plataforma = this.retornaSessao(
          settings.fastSessaoPlataforma
        ).id_plataforma;
        fast_curso_plataforma.id_usuario_responsavel = this.retornaSessao(
          settings.fastSessaoUsuario
        ).Id_Usuario;
        fast_curso_plataforma.quantidade = "1";

        let model = {
          fast_curso: fast_curso,
          fast_curso_plataforma: fast_curso_plataforma,
        };

        fetch(
          `${settings.endApiFastEad}api/fast_curso/insere`,
          this.fastAjaxOptions("POST", JSON.stringify(model))
        )
          .then((resp) => resp.json())
          .then((obj) => {
            console.log(obj);
            this.$store.state.fastCarregando = false;
            if (obj.length) {
              window.location.href =
                "/plataforma/" +
                this.retornaSessao(settings.fastSessaoPlataforma)
                  .id_plataforma +
                "/admin/curso/" +
                obj[0].id_curso;
            }
          })
          .catch((e) => {
            this.$store.state.fastCarregando = false;
            console.log(e);
          });
      }
    },
    insereCursoMae(curso) {
      // Monta objeto para ser enviado via POST
      let fast_curso_plataforma = {
        id_plataforma: this.$route.params.id_plataforma,
        id_curso: curso.id_curso,
      };

      fetch(
        `${settings.endApiFastEad}api/fast_curso_plataforma/insere_curso_mae`,
        this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma))
      )
        .then((resp) => resp.json())
        .then((obj) => {
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Curso adicionado com sucesso.",
          });

          this.getFastCursos();
        })
        .catch((e) => console.log(e));
    },
    filtraCursoPublicado() {
        this.fastMeusCursosAutoComplete = this.fastMeusCursos;
      
        if (this.filtroCursosPublicados != "3") {
          this.fastMeusCursosAutoComplete = this.fastMeusCursosAutoComplete.filter((c) => {
          return c.publicado == this.filtroCursosPublicados;
        });
        }
        
        
      
    }
  },
};
</script>

<style></style>
